import React from "react";
import Banner from "../../components/home/Banner";
import Ongoing from "../../components/projects/Ongoing";
import ProjectLogos from "../../components/home/ProjectLogos";
import CampaignModal, {
  CampaignContextProvider,
  useCampaignContext
} from "../../components/campaign/CampaignModal";
import CampaignHeader from "../../components/campaign/CampaignHeader";

const Campaign = () => {
  return (
    <CampaignContextProvider>
      <CampaignHeader />
      <div className="page-container">
        <Banner height="500px" />
        <CampaignModal />
        <CampaignOngoing />
        <ProjectLogos />
      </div>
    </CampaignContextProvider>
  );
};

const CampaignOngoing = () => {
  const { setModal } = useCampaignContext();

  return (
    <Ongoing
      projectOnClick={e => {
        setModal(true);
        e.preventDefault();
      }}
    />
  );
};

export default Campaign;
